
const tools = {
    formatNumber(num, str) {
        if (num) {
            let parts = num.toString().split(".");
            let integerPart = parts[0];
            let decimalPart = parts.length > 1 ? "." + parts[1] : "";
            let formatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, str || ".");
            return formatted + decimalPart;
        }
        return 0
    },
    trimQuotes(str) {
        if (str == "") {
            return "";
        }
        return str.replace(/^["']|["']$/g, '');
    }
}


export default tools