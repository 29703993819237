/**
 * 路由配置信息
 *
 * @author terrfly
 * @site https://www.jeepay.vip
 * @date 2021/5/8 07:18
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

// hack router push callback
// [解决 vue-router跳转相同路径报错 ]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  { path: '/pay/callback/:url', name: 'PayCallback', component: () => import('../views/Hub.vue') }, //灰陶跳转
  { path: '/hub/:aiopayToken', name: 'Hub', component: () => import('../views/Hub.vue') }, //自动分发器
  { path: '/error', name: 'Error', component: () => import('../views/Error.vue') },
  { path: '/oauth2Callback/:aiopayToken', name: 'Oauth2Callback', component: () => import('../views/Oauth2Callback.vue') }, //oauth回调地址
  {
    path: '/cashier', name: 'Cashier', component: () => import('../views/Cashier.vue'), //收银台（该地址无意义）
    children: [
      { path: '/cashier/wxpay', name: 'CashierWxpay', component: () => import('../views/payway/Wxpay.vue') },
      { path: '/cashier/alipay', name: 'CashierAlipay', component: () => import('../views/payway/Alipay.vue') },
      { path: '/cashier/ysfpay', name: 'CashierYsfpay', component: () => import('../views/payway/Ysfpay.vue') },
      { path: '/cashier/walletpay', name: 'CashierWalletPay', component: () => import('../views/payway/Walletpay.vue') },
      { path: '/cashier/success', name: 'CashierPayResultSuccess', component: () => import('../views/payway/paySuccess.vue') },
      { path: '/cashier/fail', name: 'CashierPayResultFail', component: () => import('../views/payway/payFail.vue') },
      { path: '/cashier/paywait', name: 'CashierPayWait', component: () => import('../views/payway/payWait.vue') },
      //印尼支付渠道
      { path: '/cashier/rppay', name: 'CashierRppayPages', component: () => import('../views/payway/indonesia/Payoption.vue') },
      { path: '/cashier/paybank', name: 'CashierBankPages', component: () => import('../views/payway/indonesia/Paybank.vue') },
      { path: '/cashier/bankdetail', name: 'CashierBankdetailPages', component: () => import('../views/payway/indonesia/Bankdetail.vue') },
      { path: '/cashier/qrcodedetail', name: 'CashierQrcodedetailPages', component: () => import('../views/payway/indonesia/Qrcodedetail.vue') },
      { path: '/cashier/payoption', name: 'CashierPayoptionPages', component: () => import('../views/payway/indonesia/Payoption.vue') },
      { path: '/cashier/rp/paysucced', name: 'CashierPayoptionPages', component: () => import('../views/payway/indonesia/PaySuccess.vue') },
      { path: '/cashier/rp/payfail', name: 'CashierPayoptionPages', component: () => import('../views/payway/indonesia/Payfail.vue') }
    ]
  }
]

const router = new VueRouter({
  //mode: 'hash', //history 需要nginx适配    hash：是#的格式。
  mode: 'history',
  base: "",
  routes
})

export default router
